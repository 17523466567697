<template>
<div>
    <!-- Navbar -->
    <nav class="navbar navbar-color" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">
                <router-link to="/" class="navbar-item">
                    <img src="../../../public/logo.png" width="50" height="50">
                    <h1 class="heading">Square<span>Garden</span></h1>
                </router-link>
                <span class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarHomepage" @click="toggleNavmenu">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </div>
            <div id="navbarHomepage" class="navbar-menu" :class="mobile">
                <div class="navbar-start">
                    <div class="navbar-item">
                        <button id="installbutton" class="button is-success is-inverted install-button hide"><i class="fas fa-download"></i>installieren</button>
                        <span id="iOSInstall" class="hide">To install press <img src="../../../public/img/add-to-homescreen.png" alt="add-to-home-screen" class=ios> and add to Homescreen</span>
                    </div>
                </div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <span class="link"><router-link to="/product" style="color: black;">{{ $t("nav.product.value")}}</router-link></span>
                    </div>
                    <div class="navbar-item">
                        <span class="link"><router-link to="/contact" style="color: black;">{{ $t("nav.contact.value")}}</router-link></span>
                    </div>
                    <div class="navbar-item">
                        <div class="buttons">
                            <router-link to="/login" class="button is-success is-inverted"><i class="far fa-user"></i>Login</router-link>
                            <router-link to="/register" class="button is-success is-inverted"><i class="far fa-envelope-open"></i>{{ $t("nav.register.value")}}</router-link>
                            <languageSwitcher />
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </nav>
    <!-- Hero section -->
    <section class="hero is-info">
    <div class="hero-body container">
        <p class="title">
            {{ $t("register.hero.heading")}}
        </p>
        <p class="subtitle">
            {{ $t("register.hero.text")}}
        </p>
    </div>
    </section>
    <!-- Register Form -->
    <br>
    <br>
    <div class="container align-center">
        <form class="field">
            <label for="email" class="label">{{ $t("register.email.value")}}</label>
            <div class="control has-icons-left">
                <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                </span>
                <input id="email" type="email" v-model="email" class="input" required />
                <p class="help is-danger">{{ email_error }}</p>
            </div>
            <label for="password" class="label">{{ $t("register.password.value")}}</label>
            <div class="control has-icons-left">
                <span class="icon is-small is-left">
                    <i class="fas fa-key"></i>
                </span>
                <input id="password" type="password" v-model="password" class="input" required />
                <p class="help is-danger">{{ password_error }}</p>
            </div>
            <label for="password-repeat" class="label">{{ $t("register.password.repeat")}}</label>
            <div class="control has-icons-left">
                <span class="icon is-small is-left">
                    <i class="fas fa-key"></i>
                </span>
                <input id="password_repeat" type="password" v-model="password_repeat" class="input" required />
                <p class="help is-danger">{{ password_error }}</p>
            </div>
            <div class="center">
                <div class="top-margin">
                    <button class="button is-info" @click="register">{{ $t("register.button.value")}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import { createAccountWithEmail } from "../../db/auth.js";
import languageSwitcher from "@/components/multilang/languageSwitcher";

export default {
    name: 'Register',
    components: {
        languageSwitcher,
    },
    data() {
        return {
            email: "",
            password: "",
            password_repeat: "",
            email_error: "",
            password_error: "",
            mobile: "",
        }
    },
    methods: {
        validateEmail(email) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                return false;
            } else {
                return true;
            }
        },
        register(e) {
            e.preventDefault();

            const email_field = document.getElementById("email");
            const password_field = document.getElementById("password");
            const password_repeat_field = document.getElementById("password_repeat");

            if((this.validateEmail(this.email)) || (this.password != this.password_repeat)){
                if(this.validateEmail(this.email)){
                    //validirung der email
                    email_field.classList.add("is-danger");
                    this.email_error = "This is not a valid e-mail!";
                } else {
                    //deleting errors
                    email_field.classList.remove("is-danger");
                    this.email_error = "";
                }
                if(this.password != this.password_repeat) {
                    //validierung der passwörter
                    password_field.classList.add("is-danger");
                    password_repeat_field.classList.add("is-danger");
                    this.password_error = "Passwords have to be the same and at minimum 6 characters long!";
                } else {
                    //deleting errors
                    password_repeat_field.classList.remove("is-danger");
                    password_field.classList.remove("is-danger");
                    this.password_error = "";
                }
            } else {
                //registration process
                createAccountWithEmail(this.email, this.password);
            }
        },
        //UI Methods
        toggleNavmenu(e) {
            //Toggling the burger-menu
            if(!e.target.classList.contains("is-active")){
                e.target.classList.add("is-active")
                this.mobile="is-active";
            } else {
                e.target.classList.remove("is-active");
                this.mobile="";
            }
        }
    }
}
</script>

<style scoped>
.navbar-color {
    background: #94C973;
}

h1.heading {
    text-transform: uppercase;
    color: #107869;
    font-size: 18px;
    margin-top: 8px;
    margin-left: 16px;
    font-weight: 600;
}

.link{
    font-size: 1.1rem;
    font-weight: 500;
}

i{
    margin-right: 5px;
}

img.ios {
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.hide {
    visibility: hidden;
}

/* AB HIER ÄNDERN*/

.top-margin {
    margin-top: 15px;
}

.align-center {
    margin: 0 auto;
}

@media screen and (max-width: 1023px) {
    .align-center {
        margin: 0 20px;
    }
}

</style>